import * as React from 'react'
import { graphql } from 'gatsby'

import CategoryPageLayout from '../components/category-page/layout'

export const query = graphql`
  query ($sectionId: String) {
    section(id: {eq: $sectionId}) {
      ...CategoryPageSectionFragment

      products {
        ...CategoryPageProductFragment
        ...ProductCardFragment
      }
    }
  }
`

const SectionPageTemplate = ({ data: { section }, location }) => {
  return (
    <CategoryPageLayout
      section={section}
      category={null}
      filterRules={section.filterRules}
      products={section.products}
      location={location}
    />
  )
}

export default SectionPageTemplate
